import React from 'react';
import {Helmet} from 'react-helmet';
class HomeTitle extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        let content =  "";
        if(this.props.systemConfig.systemNameCn) {
            content = this.props.systemConfig.systemNameCn + " " + this.props.systemConfig.systemOrientation
        }

        let title = (
            <Helmet>
            <title
                id="homeTitle">{content} </title>
            </Helmet>
        );
        return title
    }

}

export default HomeTitle;
