import React from 'react';
import {Helmet} from 'react-helmet';
class HomeDescription extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        let description = (
            <Helmet>
            <meta name="description" id="descriptionMeta"
                  content={this.props.systemConfig.systemDescription}/>
            </Helmet>
        );
        return description
    }

}

export default HomeDescription;
