import React from 'react';
import ViewHeader from './ViewHeader';
import ViewContainer from './ViewContainer';
import ViewFooter from './ViewFooter';
import './view.css';
import {Breadcrumb, Button, Card, Col, Divider, Image, Input, Layout, Pagination, Row, Space, Spin} from "antd";
import { Link , Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";

import ViewTitle from './ViewTitle';
import ViewKeyWords from './ViewKeyWords';
import ViewDescription from './ViewDescription';
import ViewContentMeta from './ViewContentMeta';
import ViewView from './ViewView';

import {
    AppstoreOutlined,
    SearchOutlined,
    LoginOutlined,
    PieChartOutlined,
    DesktopOutlined,
    MailOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    HomeOutlined,
    ContainerOutlined,
    LoadingOutlined
} from '@ant-design/icons';

const { Meta } = Card;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const  {Content, Footer} = Layout;


class View extends React.Component {

    constructor(props){
        super(props)

        this.state ={
            systemConfig: {},
            viewPlate: {},
            items: {}
        }
    }

    loadView(viewId, pageNo) {

        this.setState({
            loading: true
        })

        let formData = new FormData()
        formData.append('id', viewId)

        let host = process.env.REACT_APP_HOST

        fetch(host + "/site/view/detailView", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {

                console.log("data.data:", data.data)
                this.setState({
                    viewPlate: data.data.map.viewPlate,
                    systemConfig: data.data.map.systemConfig
                })

                var html = {__html:this.state.viewPlate.content};
                var content = <div dangerouslySetInnerHTML={html}></div>;
                var attachment = <></>
                if(this.state.viewPlate.attachment) {
                    attachment = <Image src={this.state.viewPlate.attachment} />
                }

                var video = <></>
                if((this.state.viewPlate.iframe != "") && (this.state.viewPlate.hasVideo === 'Y')) {
                    video = <iframe src={this.state.viewPlate.iframe} style={{width: 400, height: 300}}/>
                }

                var parent = <></>

                if(this.state.viewPlate.parent) {
                    var parentLink = "/site/view/" + this.state.viewPlate.parent.id
                    parent = <Breadcrumb.Item><a href={parentLink}>{this.state.viewPlate.parent.title}</a></Breadcrumb.Item>
                }

                this.setState({
                    video: video,
                    parent: parent,
                    content: content,
                    attachment: attachment
                })

                if(this.state.viewPlate.isParent != 'Y') {
                    this.setState({
                        items: [],
                        loading: false
                    })
                } else {
                    this.loadSubView(viewId, pageNo)
                }

            })

    }

    loadSubView(viewId, pageNo) {
        console.log("viewId: ", viewId)
        console.log("pageNo:", pageNo)
        if(!pageNo) pageNo = 1
        let formData = new FormData()
        formData.append('id', viewId)
        formData.append('pageNo', pageNo)

        let host = process.env.REACT_APP_HOST



        fetch(host + "/site/view/subPage", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {

                console.log("data.data:", data.data)
                this.setState({
                    subView: data.data.map.pageModel.recordList,
                    page: data.data.map.pageModel,
                })

                let items = this.extracted();

                this.setState({
                    items: items,
                    loading: false
                })


            })

    }

    extracted() {
        let items = []

        for (var subView in this.state.subView) {
            var link = "/site/view/" + this.state.subView[subView].id
            var item = (
                <a href={link}>
                    <Card
                        hoverable
                        style={{width: 230}}
                        cover={<img height="300" alt={this.state.subView[subView].title}
                                    src={this.state.subView[subView].attachment}/>}
                    >
                        <Meta style={{height: 40}} title={this.state.subView[subView].title}
                              description={this.state.subView[subView].description}/>
                    </Card>
                </a>
            );
            items.push(item)
        }
        return items;
    }

    onChange = (page) => {
        console.log(page);
        this.setState({
            current: page,
            loading: true,
        });

        var viewId = this.state.viewPlate.id

        this.loadView(viewId, page)
    };


    componentDidMount() {
        console.log("mount")
        console.log(this.props.match.params.id);
        var viewId = this.props.match.params.id

        this.loadView(viewId, 1)

    }


    componentWillReceiveProps(nextProps, nextContext) {
        console.log("receive props")
        console.log(this.props.match.params.id);
        var viewId = this.props.match.params.id
        if(viewId != this.state.viewPlate.id){
            this.loadView(viewId)
        }

    }


    render() {
        if(this.state.viewPlate.useUrl === 'Y') {
            window.location.href=this.state.viewPlate.iframe

        }

        return (
            <>
                <ViewTitle viewPlate={this.state.viewPlate} systemConfig={this.state.systemConfig}></ViewTitle>
                <ViewKeyWords viewPlate={this.state.viewPlate} systemConfig={this.state.systemConfig}></ViewKeyWords>
                <ViewDescription viewPlate={this.state.viewPlate} systemConfig={this.state.systemConfig}></ViewDescription>
                <ViewContentMeta viewPlate={this.state.viewPlate} systemConfig={this.state.systemConfig}></ViewContentMeta>

                <Layout>


                    <Layout style={{height:30, backgroundColor: '#ffffff'}}>

                    </Layout>
                    <Layout style={{height:60, backgroundColor: '#ffffff'}}>

                        <Row justify="space-around">
                            <Col span={6}>
                                <Space  align="center"  size={'middle'}>
                                    <div></div>

                                    <div className="logo" ><a href={this.state.systemConfig.domain}>{this.state.systemConfig.systemNameCn}</a></div>
                                    <div></div>

                                </Space>

                            </Col>
                            <Col span={6}><Input></Input></Col>
                            <Col span={6}><Button icon={<SearchOutlined/>}></Button></Col>
                            <Col span={6}><Button icon={<LoginOutlined/>}>登录</Button></Col>
                        </Row>
                    </Layout>
                    <Layout>

                        <Content
                            className="site-layout-background"
                            style={{
                                paddingTop: 5,
                                minHeight: 280,
                            }}
                        >

                            <div style={{backgroundColor:'#ffffff', padding: 10, margin: 0}}>
                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item>
                                        <a href="/"><HomeOutlined /></a>
                                    </Breadcrumb.Item>
                                        {this.state.parent}
                                    <Breadcrumb.Item>{this.state.viewPlate.title}</Breadcrumb.Item>
                                </Breadcrumb>

                                <Divider />

                                <Row>
                                    <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                                    </Col>
                                    <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                                        <Space align='center'>{this.state.attachment}</Space>
                                    </Col>
                                    <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                                    </Col>
                                    <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                                        <Space align='center'>{this.state.video}</Space>
                                    </Col>
                                    <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                                    </Col>
                                </Row>

                                <Spin indicator={antIcon} spinning={this.state.loading}>
                                    <Row>
                                        <Col span={24}>
                                            {this.state.items.length>0?<Pagination showSizeChanger={false} onChange={this.onChange} defaultCurrent={this.state.page.pageNo} pageSize={this.state.page.pageSize} total={this.state.page.totalRecord} />:<p></p>}
                                        </Col>

                                    </Row>
                                    <Space  align='start' wrap>
                                        {this.state.items.length>0?this.state.items:this.state.content}
                                    </Space>
                                </Spin>

                            </div>
                        </Content>
                    </Layout>


                    <ViewFooter systemConfig={this.state.systemConfig}></ViewFooter>
                </Layout>

            </>
        )
    }

}

export default View;
