import React from 'react';
import {Card, message, Layout, Menu, Form, Input, Button, Checkbox, Space, Col, Row, Image} from 'antd';
import {Breadcrumb} from "antd/lib/breadcrumb";
import {
    EditOutlined,
    UserOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import BuddyTitle from "./BuddyTitle";

const { Meta } = Card;

const { Header, Footer, Sider, Content } = Layout;

const { TextArea } = Input;



class BuddyHome extends React.Component {

    formRef = React.createRef();

    constructor(...args) {
        super(...args);
        this.state = {
            loading: false,
            systemConfig: {},
            visiting: {}
        };
    }

    buddyNote = e => {

        if(this.state.visiting.data.map.loginBuddy) {
            window.location.href="/site/buddy/"+this.state.visiting.data.map.loginBuddy.buddyName+"/note"
        } else {
            window.location.href="/site/buddy/login"
        }

    };

    buddyModify = e => {

        if(this.state.visiting.data.map.loginBuddy) {
            window.location.href="/site/buddy/"+this.state.visiting.data.map.loginBuddy.buddyName+"/profile"
        } else {
            window.location.href="/site/buddy/login"
        }

    };

    buddyLogout= e => {

        if(this.state.visiting.data.map.loginBuddy) {
            let formData = new FormData()
            formData.append('buddyName', this.state.visiting.data.map.loginBuddy.buddyName)

            let host = process.env.REACT_APP_HOST

            fetch(host + "/site/buddy/api/logoutApi", {method: 'POST', body: formData})
                .then(response => response.json())
                .then(data => {

                    console.log("data:", data)
                    if(data.success) {
                        message.info(data.msg)

                        window.location.href="/site/buddy/login"

                    }


                })
        } else {
            window.location.href="/site/buddy/login"
        }

    };

    onFinish = (values) => {


        console.log("values:", values)

        let formData = new FormData()
        formData.append('nickName', values["buddy.nickName"])
        formData.append('email', values["buddy.email"])
        formData.append('question', values["buddy.question"])
        formData.append('answer', values["buddy.answer"])
        formData.append('birthday', values["buddy.birthday"])
        formData.append('phone', values["buddy.phone"])
        formData.append('mood', values["buddy.mood"])
        formData.append('wish', values["buddy.wish"])

        let host = process.env.REACT_APP_HOST

        fetch(host + "/site/buddy/modify", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {

                console.log("data:", data)
                if(data.success) {
                    message.info("修改成功！")

                }


            })


    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    loadSystemConfig(nextProps) {
        const {app} = nextProps
        let formData = new FormData()
        formData.append('app', app)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/view/systemConfig", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;
                this.setState({
                    systemConfig: data.data.map.systemConfig,
                    loading: false
                })

            })

    }


    loadAuth(buddyName) {
        let formData = new FormData()
        formData.append('buddyName', buddyName)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/buddy/api/auth", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                console.log("auth data:", data)
                if(data.success) {


                    if(data.data.map.loginBuddy) {
                        this.setState({
                            auth: data,
                            visiting: data,
                            loading: false
                        })

                    } else {
                        window.location.href="/site/buddy/login"
                    }


                }


            })

    }

    componentDidMount() {
        console.log("mount")
        this.loadSystemConfig({app: this.props.app})
        console.log(this.props.match.params.buddyName);
        var buddyName = this.props.match.params.buddyName

        this.loadAuth(buddyName)

    }


    render() {
        return (
            <>
                <BuddyTitle content="主菜单" systemConfig={this.state.systemConfig}></BuddyTitle>

                <Layout>
                <Header  style={{color:'#ffffff'}}>{this.state.systemConfig.systemNameCn} 用户菜单</Header>
                <Content style={{height: 400}}>
            
                    <Row gutter={[16, 16]}  justify="center">
                        <Col span={16}  style={{color: '#ffffff'}}>
                            <Space/>
                        </Col>

                        <Col span={16}  style={{color: '#ffffff'}}>

                            <Card onClick={this.buddyNote}
                                  hoverable
                                  style={{ backgroundColor: '#ffffff' }}
                            >
                                <Space>
                                    <EditOutlined  style={{ fontSize: '32px'}}/>
                                    <Meta title="" description="管理好记" />
                                </Space>
                            </Card>
                        </Col>
                        <Col span={16}  style={{color: '#ffffff'}}>

                            <Card onClick={this.buddyModify}
                                  hoverable
                                  style={{ backgroundColor: '#ffffff' }}
                            >
                                <Space>
                                    <UserOutlined style={{ fontSize: '32px'}}/>
                                    <Meta title="" description="修改资料" />
                                </Space>
                            </Card>
                        </Col>
                        <Col span={16}  style={{color: '#ffffff'}}>
                            <Card onClick={this.buddyLogout}
                                hoverable
                                style={{ backgroundColor: '#ffffff' }}

                            >
                                <Space>
                                <LogoutOutlined style={{ fontSize: '32px'}}/>
                                <Meta title="" description="退出登录" />
                                </Space>
                            </Card>
                        </Col>


                    </Row>

                </Content>
                <Footer style={{height:60, backgroundColor: '#ffffff'}}>
                    <Row justify="space-around">
                        <Col span={6}>
                            <Space direction='vertical'>
                                <div>站点：<a href={this.state.systemConfig.domain}>{this.state.systemConfig.systemNameCn}</a></div>
                                <div>Powered by {this.state.systemConfig.domain} ©{this.state.systemConfig.systemCopyRight}</div>
                            </Space>
                        </Col>
                    </Row>

                </Footer>
            </Layout>
                </>


        );


    }

}

export default BuddyHome;