import React from 'react';
import {Helmet} from "react-helmet";

class ViewTitle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initData: ''
        };
    }

    componentDidMount() {
        console.log("mount")
        console.log("title:", this.props.viewPlate);


    }

    render() {

        let content =  "";
        if(this.props.viewPlate) {
            content = this.props.viewPlate.title
        }

        let title = (
            <Helmet>
            <title
                id="homeTitle">{content}</title>
            </Helmet>
        );
        return title
    }

}

export default ViewTitle;
