import React from 'react';
import {Helmet} from "react-helmet";

class BuddyTitle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initData: ''
        };
    }

    componentDidMount() {
        console.log("mount")
    }

    render() {

        let title = (
            <Helmet>
            <title
                id="loginTitle">{this.props.content}</title>
            </Helmet>
        );
        return title
    }

}

export default BuddyTitle;
