import React from 'react';
import {Helmet} from 'react-helmet';
class HomeKeyWords extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        let keywords = (
            <Helmet>
            <meta name="keywords" id="keywordsMeta"
                  content={this.props.systemConfig.systemKeyWords}/>
            </Helmet>
        );
        return keywords
    }

}

export default HomeKeyWords;
