import React from 'react';
import {Drawer, Modal, List, Avatar, Spin, message, Layout, Menu, Form, Input, Button, Checkbox, Space, Col, Row, Image} from 'antd';
import { DrawerProps } from 'antd/es/drawer';
import {
    LoadingOutlined,
    EditOutlined,
    HomeOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import reqwest from 'reqwest';

import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VList from 'react-virtualized/dist/commonjs/List';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';



class InfiniteList extends React.Component {

    formRef = React.createRef();

    constructor(...args) {
        super(...args);
        this.state = {
            type: "",
            data: [],
            recordList: [],
            page: 1,
            pageNo: 1,
            totalRecord: 0,
            loading: false,
            systemConfig: {},
            visiting: {},
            loginBuddy: "",
            visible: false,
            content:"",
        };


    }


    loadedRowsMap = {};

    showDrawer = (content) => {
        this.setState({
            content: content,
        })
        this.setState({
            visible: true,
        })
    };

    onClose = () => {
        this.setState({
            visible: false,
        })
    };

    fetchData = (callback, page) => {
        let host = process.env.REACT_APP_HOST
        console.log("fetchData:" + this.state.pageNo)
        this.setState(
            {
                page: this.state.page + 1,
            }
        )

        let type = this.state.type

        reqwest({
            url: host + "/site/buddy/" + type + "/page",
            type: 'json',
            data: {
                buddyName: this.state.loginBuddy,
                pageNo:page,
            },
            method: 'post',
            success: res => {
                callback(res);
            },
        });
    };

    handleInfiniteOnLoad = ({ startIndex, stopIndex }) => {
        let { data } = this.state;
        this.setState({
            loading: true,
        });
        for (let i = startIndex; i <= stopIndex; i++) {
            // 1 means loading
            this.loadedRowsMap[i] = 1;
        }
        if (this.state.page > this.state.pageTotal) {
            message.warning('没有更多了！总记录：' + this.state.totalRecord);
            this.setState({
                loading: false,
            });
            return;
        } else {
            this.fetchData(res => {
                data = data.concat(res.data.map.pageModel.recordList);
                this.setState({
                    data,
                    recordList: res.data.map.pageModel.recordList,
                    totalRecord: res.data.map.pageModel.totalRecord,
                    pageTotal: res.data.map.pageModel.pageTotal,
                    pageNo: res.data.map.pageModel.pageNo,
                    loading: false,
                });
            }, this.state.page);
        }

    };

    renderItem = ({ index, key, style }) => {
        const { data } = this.state;
        const item = data[index];
        return (
            <>
            <List.Item key={key} style={style} >
                <List.Item.Meta

                    avatar={<Avatar src={this.state.systemConfig.imageServer + item.buddy.photoDfs} />}
                    title={<a href={"/site/buddy/"+item.buddy.buddyName+"/home"}>{item.buddy.buddyName}</a>}
                    description={item.remark}

                />
                <div style={{height: 48, overflow:'hidden'}} >
                    <a href={"/site/buddy/"+item.buddy.buddyName+"/noteDetail/"+item.id}>{item.content}</a>
                </div>

                    </List.Item>

                </>
        );
    };

    isRowLoaded = ({ index }) => !!this.loadedRowsMap[index];

    loadSystemConfig(nextProps) {
        const {app} = nextProps
        let formData = new FormData()
        formData.append('app', app)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/view/systemConfig", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;
                this.setState({
                    systemConfig: data.data.map.systemConfig,
                    loading: false
                })

            })

    }

    loadAuth(buddyName) {
        let formData = new FormData()
        formData.append('buddyName', buddyName)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/buddy/api/auth", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                console.log("auth data:", data)
                if(data.success) {


                    if(data.data.map.loginBuddy) {

                        this.setState({
                            auth: data,
                            visiting: data,
                            loginBuddy: data.data.map.loginBuddy.buddyName,
                            loading: false
                        })


                        this.fetchData(res => {
                            console.log("page data:")
                            console.log(res)
                            this.setState({
                                data: res.data.map.pageModel.recordList,
                            });
                        });



                    } else {
                        window.location.href="/site/buddy/login"
                    }


                }


            })

    }

    componentDidMount() {
        console.log("mount")
        this.loadSystemConfig({app: this.props.app})

        console.log("buddyName")
        console.log(this.props.buddyName)

        this.setState({
            loginBuddy: this.props.buddyName,
            type: this.props.type,
        })

        this.loadAuth(this.props.buddyName)

    }


    render() {
        const { data } = this.state;

        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

        const vlist = ({ height, isScrolling, onChildScroll, scrollTop, onRowsRendered, width }) => (
            <VList
                autoHeight
                height={height}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                rowCount={data.length}
                rowHeight={100}
                rowRenderer={this.renderItem}
                onRowsRendered={onRowsRendered}
                scrollTop={scrollTop}
                width={width}
            />
        );

        const autoSize = ({ height, isScrolling, onChildScroll, scrollTop, onRowsRendered }) => (
            <AutoSizer>
                {({ width }) =>
                    vlist({
                        height,
                        isScrolling,
                        onChildScroll,
                        scrollTop,
                        onRowsRendered,
                        width,
                    })
                }
            </AutoSizer>
        );

        const infiniteLoader = ({ height, isScrolling, onChildScroll, scrollTop }) => (
            <InfiniteLoader
                isRowLoaded={this.isRowLoaded}
                loadMoreRows={this.handleInfiniteOnLoad}
                rowCount={data.length}
            >
                {({ onRowsRendered }) =>
                    autoSize({
                        height,
                        isScrolling,
                        onChildScroll,
                        scrollTop,
                        onRowsRendered,
                    })
                }
            </InfiniteLoader>
        );


        return (
            <>

                <List itemLayout="vertical" size="large">
                    {data.length > 0 && <WindowScroller>{infiniteLoader}</WindowScroller>}
                    {this.state.loading && <Spin indicator={antIcon}/>}
                </List>
                <Drawer
                    title="详情"
                    width={500}
                    placement='top'
                    onClose={this.onClose}
                    visible={this.state.visible}
                    extra={
                    <Space>
                        <Button onClick={this.onClose}>关闭</Button>
                    </Space>
                }
                >
                <p>{this.state.content}</p>
                </Drawer>
                </>

        );


    }

}

export default InfiniteList;