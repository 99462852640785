import React from 'react';
import ViewView from './ViewView';
import {Divider, Carousel, Tooltip, Row, Col, Layout, Menu, Button, Input, Space} from 'antd';

import {
    AppstoreOutlined,
    SearchOutlined,
    LoginOutlined,
    PieChartOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
} from '@ant-design/icons';

const {Header, Sider, Content, Footer } = Layout;

const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};


class ViewContainer extends React.Component {

    constructor(...args) {
        super(...args);
        this.state = {
            initData: ''
        };
    }


    goLogin = () => {
        window.location.href="/site/buddy/login";
    }

    render() {

        let container = (
            <>
                <Layout style={{height:30, backgroundColor: '#ffffff'}}>

                </Layout>
                <Layout style={{height:60, backgroundColor: '#ffffff'}}>

                    <Row justify="space-around">
                        <Col span={6}>
                            <Space  align="center"  size={'middle'}>
                                <div></div>

                                <div className="logo" ><a href={this.props.systemConfig.domain}>{this.props.systemConfig.systemNameCn}</a></div>
                                <div></div>

                            </Space>

                        </Col>

                        <Col span={6}><Button onClick={this.goLogin} icon={<LoginOutlined/>}>登录</Button></Col>
                    </Row>
                </Layout>
                <Layout>

                    <Content
                        className="site-layout-background"
                        style={{
                            paddingTop: 5,
                            minHeight: 280,
                        }}
                    >


                        <ViewView page={this.props.page} systemConfig={this.props.systemConfig} viewPlate={this.props.viewPlate} subView={this.props.subView}></ViewView>
                    </Content>
                </Layout>
            </>
        );
        return container
    }

}

export default ViewContainer;
