import React from 'react';
import { Spin, Card, Carousel, Tooltip, Row, Col, Layout, Menu, Button, Input, Space } from 'antd';
import { Link } from 'react-router-dom'
import View from '../view/View';

import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const gridStyle = {
    textAlign: 'center',
};

const { Meta } = Card;

class HomeView extends React.Component {

    constructor(...args) {
        super(...args);
        this.state = {
            loading: false
        };
    }

    loadIndexView(nextProps) {
        const {site} = nextProps
        let formData = new FormData()
        formData.append('site', site)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({
            loading: true
        })



        fetch(host + "/site/view/indexMapView", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                console.log("data.data:", data.data.map.indexList)
                this.setState({
                    indexViewList: data.data.map.indexList,
                    indexViewMap: data.data.map.indexMap
                })

                this.setState({
                    loading: false
                })


            })

    }



    componentDidMount() {
        this.loadIndexView({site: this.props.systemConfig.domainConfig})
    }

    render() {

        let items = []
        console.log("this.state.indexViewList:", this.state.indexViewList)

        for(var indexView in this.state.indexViewList) {
            console.log("indexView:", indexView)
            let subList = []
            let subView = this.state.indexViewMap[this.state.indexViewList[indexView].id]
            for(var sub in subView) {
                var link = "/site/view/" + subView[sub].id
                subList.push(
                    <a href={link}>
                    <Card type='inner'
                        hoverable
                        style={{ width: 200 }}
                        cover={<img style={{height:240}} alt={subView[sub].title} src={subView[sub].attachment} />}
                    >
                        <Meta title={subView[sub].title} description={subView[sub].description} />
                    </Card>
                    </a>
                )
            }
            var link = "/site/view/" + this.state.indexViewList[indexView].id
            const title = <a href={link}>{this.state.indexViewList[indexView].title}</a>
            var item = (
                <Card title={title}>

                    <Space align='start' wrap>
                        {subList}
                    </Space>


                </Card>

            );
            items.push(item)

        }

        let views = (
            <div>
                <Spin spinning={this.state.loading} indicator={antIcon}>
                    {items}
                </Spin>

            </div>
        );
        return views
    }

}

export default HomeView;
