import React from 'react';
import {Helmet} from 'react-helmet';
class HomeContentMeta extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        let contentMeta = (
            <Helmet>
            <meta name="" id="nameContentMeta" content={this.props.systemConfig.metaContent}/>
            </Helmet>
        );
        return contentMeta
    }

}

export default HomeContentMeta;
