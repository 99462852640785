import React from 'react';
import { Spin, Card, Carousel, Tooltip, Row, Col, Layout, Menu, Button, Input, Space } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const { Meta } = Card;

class HomeView extends React.Component {

    constructor(...args) {
        super(...args);
        this.state = {
            loading: false
        };
    }

    loadRecommendView(nextProps) {
        const {site} = nextProps
        let formData = new FormData()
        formData.append('site', site)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({
            loading: true
        })


        fetch(host + "/site/view/recommend", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                console.log("data.data:", data.data)
                this.setState({
                    recommendViewList: data.data.map.pageModel.recordList
                })

                this.setState({
                    loading: false
                })


            })

    }

    componentDidMount() {
        this.loadRecommendView({site: this.props.systemConfig.domainConfig})
    }

    render() {

        let items = []
        console.log("this.state.recommendViewList:", this.state.recommendViewList)

        for(var recommendView in this.state.recommendViewList) {
            console.log("recommendView:", recommendView)
            var link = '/site/view/' + this.state.recommendViewList[recommendView].id
            var item = (
                <Col>
                <a href={link}>
                    <Card type='inner'
                        hoverable
                        style={{ width: 200 }}
                        cover={<img style={{height:240}} alt={this.state.recommendViewList[recommendView].title} src={this.state.recommendViewList[recommendView].attachment} />}
                    >
                    <Meta title={this.state.recommendViewList[recommendView].title} description={this.state.recommendViewList[recommendView].description} />
                    </Card>
                </a>
                </Col>
            );
            items.push(item)
        }

        let views = (
                <div className="site-card-wrapper">
                    <Card title="推荐栏目">
                        <Spin spinning={this.state.loading} indicator={antIcon}>
                        <Space align='start' wrap>
                    {items}
                        </Space>
                        </Spin>
                    </Card>
                </div>
        );
        return views
    }

}

export default HomeView;
