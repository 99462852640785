import React from 'react';
import {Modal, List, Avatar, Spin, message, Layout, Menu, Form, Input, Button, Checkbox, Space, Col, Row, Image} from 'antd';
import {Breadcrumb} from "antd/lib/breadcrumb";
import {
    LoadingOutlined,
    EditOutlined,
    HomeOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined,
} from '@ant-design/icons';

import BuddyMenu from "../BuddyMenu";
import BuddyTitle from "../BuddyTitle";
import NoteForm from "./NoteForm";
import NoteList from "./NoteList"

const { Header, Footer, Sider, Content } = Layout;


class Note extends React.Component {

    constructor(...args) {
        super(...args);
        this.state = {
            modalVisible: false,
            loading: false,
            systemConfig: {},
            visiting: {},
            loginBuddy: "",
            collapsed: true
        };




    }


    setRefresh = (noteList) => {


        this.loadAuth()
    }


    showModal = (content) => {
        if(!this.state.loading && this.state.modelContent) {
            this.setState(
                {
                    modalVisible: true,
                    modelContent: content
                }
            )
        }


    };

    handleCancel = () => {
        this.setState(
            {
                modalVisible: false
            }
        )
    };

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };



    onFinish = (values) => {


        console.log("values:", values)

        let formData = new FormData()
        formData.append('content', values["note.content"])

        let host = process.env.REACT_APP_HOST

        fetch(host + "/site/buddy/note/api/addApi", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {

                console.log("data:", data)
                if(data.success) {
                    message.info("发送成功！")

                }


            })


    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    loadSystemConfig(nextProps) {
        const {app} = nextProps
        let formData = new FormData()
        formData.append('app', app)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/view/systemConfig", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;
                this.setState({
                    systemConfig: data.data.map.systemConfig,
                })

            })

    }


    loadAuth() {
        let formData = new FormData()

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/buddy/api/auth", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                console.log("auth data:", data)
                if(data.success) {


                    if(data.data.map.loginBuddy) {
                        this.setState({
                            auth: data,
                            visiting: data,
                            loginBuddy: data.data.map.loginBuddy.buddyName,
                            noteForm: null,
                            noteList: null

                        })

                        var noteForm = <NoteForm buddyName={this.state.loginBuddy} getRefresh={() => {this.setRefresh()}}/>

                        this.setState({
                            noteForm: noteForm,
                        })

                        var noteList = <NoteList buddyName={this.state.loginBuddy} type="note"/>

                        this.setState({
                            noteList: noteList,
                        })

                        this.setState({
                            loading: false,
                        })

                        console.log("load end")


                    } else {
                        window.location.href="/site/buddy/login"
                    }


                }


            })

    }

    componentDidMount() {
        console.log("mount")
        this.loadSystemConfig({app: this.props.app})

        this.loadAuth()

    }


    render() {

        return (
            <>
                <BuddyTitle content="管理好记" systemConfig={this.state.systemConfig}></BuddyTitle>

                <Layout>
                <Header>
                    <Space style={{color:'#ffffff'}}>
                    {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: this.toggleCollapsed,
                    })}
                </Space>
                </Header>
                    <Layout>

                <Sider collapsedWidth="0" trigger={null} collapsible collapsed={this.state.collapsed}>


                    <BuddyMenu buddyName={this.state.loginBuddy} />

                </Sider>
                <Content  style={{height:'auto'}}>

                    <Row justify="space-around">
                        <Col span={2}></Col>
                        <Col span={18}>
                            {this.state.noteForm}
                        </Col>
                        <Col span={2}></Col>
                    </Row>

                    <Row justify="space-around">
                        <Col span={20}>
                            {this.state.noteList}

                        </Col>
                    </Row>
                    <Modal title={this.state.loginBuddy}
                           visible={this.state.modalVisible}
                           onCancel={this.handleCancel}
                           footer={[]}
                    >
                        <p>{this.state.modalContent}</p>
                    </Modal>

                </Content>
                    </Layout>

            </Layout>
                </>
        );


    }

}

export default Note;