import React from 'react';
import {Modal, List, Avatar, Spin, message, Layout, Menu, Form, Input, Button, Checkbox, Space, Col, Row, Image} from 'antd';

import NoteList from "./NoteList";

const { TextArea } = Input;


class NoteForm extends React.Component {

    formRef = React.createRef();

    constructor(...args) {
        super(...args);
        this.state = {
            systemConfig: {},
            visiting: {},
            loginBuddy: "",
        };


    }

    onFinish = (values) => {


        console.log("values:", values)

        let formData = new FormData()
        formData.append('content', values["note.content"])

        let host = process.env.REACT_APP_HOST

        fetch(host + "/site/buddy/note/api/addApi", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {

                console.log("data:", data)
                if(data.success) {
                    message.info("发送成功！")

                    var noteList = <NoteList buddyName={this.state.loginBuddy} type="note"/>

                    this.setState({
                        noteList: noteList,
                    })


                    this.props.getRefresh()

                }


            })


    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    loadAuth(buddyName) {
        let formData = new FormData()
        formData.append('buddyName', buddyName)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/buddy/api/auth", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                console.log("auth data:", data)
                if(data.success) {


                    if(data.data.map.loginBuddy) {
                        this.setState({
                            auth: data,
                            visiting: data,
                            loginBuddy: data.data.map.loginBuddy.buddyName,

                        })


                    } else {
                        window.location.href="/site/buddy/login"
                    }


                }


            })

    }

    componentDidMount() {
        console.log("mount")
        this.setState({
            loginBuddy: this.props.buddyName,
        })

        this.loadAuth(this.props.buddyName)

    }


    render() {

        return (

                    <Form ref={this.formRef}
                        name="buddy"
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item label="">
                            <span className="ant-form-text"></span>
                        </Form.Item>


                        <Form.Item
                                   label=""
                                   name="note.content"

                        >
                            <TextArea rows={8} style={{minWidth: 200}}/>
                        </Form.Item>


                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                发送
                            </Button>
                        </Form.Item>
                    </Form>

        );


    }

    getNoteList(noteList) {
            console.log("getNoteList:")
            console.log(noteList)
            return noteList;

    }
}

export default NoteForm;