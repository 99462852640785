import logo from './logo.svg';
import './App.css'


import Home from './home/Home';


function App() {
  return (
      <Home app="yingyinyi.com"></Home>

  );
}

export default App;
