import React from 'react';
import {Helmet} from 'react-helmet';
import ViewTitle from './ViewTitle';
import ViewKeyWords from './ViewKeyWords';
import ViewDescription from './ViewDescription';
import ViewContentMeta from './ViewContentMeta';

class ViewHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initData: ''
        };
    }

    componentDidMount() {
        console.log("mount")
        console.log("header:", this.props.viewPlate);


    }

    render() {

        let header = (
                <Helmet>
                    <ViewTitle viewPlate={this.props.viewPlate} systemConfig={this.props.systemConfig}></ViewTitle>
                    <ViewKeyWords viewPlate={this.props.viewPlate} systemConfig={this.props.systemConfig}></ViewKeyWords>
                    <ViewDescription viewPlate={this.props.viewPlate} systemConfig={this.props.systemConfig}></ViewDescription>
                    <ViewContentMeta viewPlate={this.props.viewPlate} systemConfig={this.props.systemConfig}></ViewContentMeta>
                </Helmet>

        );
        return header
    }

}

export default ViewHeader;
