import React from 'react';

import InfiniteList from "../InfiniteList";



class NoteList extends React.Component {

    constructor(...args) {
        super(...args);
        this.state = {
            modalVisible: false,
            loading: false,
            systemConfig: {},
            visiting: {},
            loginBuddy: "",
            collapsed: true
        };




    }


    loadAuth() {
        let formData = new FormData()

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/buddy/api/auth", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                console.log("auth data:", data)
                if(data.success) {




                    if(data.data.map.loginBuddy) {
                        this.setState({
                            auth: data,
                            visiting: data,
                            loginBuddy: data.data.map.loginBuddy.buddyName,

                        })


                        var infiniteList = <InfiniteList buddyName={this.state.loginBuddy} type={this.state.type}/>

                        this.setState({
                            infiniteList: infiniteList,
                        })

                        this.setState({
                            loading: false,
                        })


                    } else {
                        window.location.href="/site/buddy/login"
                    }


                }


            })

    }

    componentDidMount() {
        console.log("mount")

        console.log("buddyName")
        console.log(this.props.buddyName)

        this.setState({
            loginBuddy: this.props.buddyName,
            type: this.props.type,
        })

        this.loadAuth()

    }


    render() {

        return (
            <>{this.state.infiniteList}</>
        );


    }

}

export default NoteList;