import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createHashHistory } from 'history';
import View from './view/View';
import Login from './buddy/Login';
import BuddyHome from './buddy/BuddyHome';
import BuddyProfile from './buddy/BuddyProfile';
import Note from './buddy/note/Note';
import NoteDetail from './buddy/note/NoteDetail';


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
        <Switch>
            <Route path="/site/view/:id" component={View}/>
            <Route path="/site/buddy/login" component={Login}/>
            <Route path="/site/buddy/:buddyName/home" component={BuddyHome}/>
            <Route path="/site/buddy/:buddyName/note" component={Note}/>
            <Route path="/site/buddy/:buddyName/noteDetail/:id" component={NoteDetail}/>
            <Route path="/site/buddy/:buddyName/profile" component={BuddyProfile}/>
            <Route exact path="/" component={App}/>

        </Switch>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
