import React from 'react';
import {message, Layout, Menu, Form, Input, Button, Checkbox, Space, Col, Row, Image} from 'antd';
import {Breadcrumb} from "antd/lib/breadcrumb";
import {
    EditOutlined,
    HomeOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import BuddyMenu from "./BuddyMenu";
import BuddyTitle from "./BuddyTitle";

const { Header, Footer, Sider, Content } = Layout;

const { TextArea } = Input;



class BuddyProfile extends React.Component {

    formRef = React.createRef();

    constructor(...args) {
        super(...args);
        this.state = {
            loading: false,
            systemConfig: {},
            visiting: {},
            loginBuddy: "",
            collapsed: true
        };
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    onFinish = (values) => {


        console.log("values:", values)

        let formData = new FormData()
        formData.append('nickName', values["buddy.nickName"])
        formData.append('email', values["buddy.email"])
        formData.append('question', values["buddy.question"])
        formData.append('answer', values["buddy.answer"])
        formData.append('birthday', values["buddy.birthday"])
        formData.append('phone', values["buddy.phone"])
        formData.append('mood', values["buddy.mood"])
        formData.append('wish', values["buddy.wish"])

        let host = process.env.REACT_APP_HOST

        fetch(host + "/site/buddy/modify", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {

                console.log("data:", data)
                if(data.success) {
                    message.info("修改成功！")

                }


            })


    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    loadSystemConfig(nextProps) {
        const {app} = nextProps
        let formData = new FormData()
        formData.append('app', app)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/view/systemConfig", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;
                this.setState({
                    systemConfig: data.data.map.systemConfig,
                    loading: false
                })

            })

    }

    loadHome(buddyName) {
        let formData = new FormData()
        formData.append('buddyName', buddyName)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/buddy/api/visiting", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                console.log("home data:", data)
                if(data.success) {
                    this.setState({
                        visiting: data,
                        loading: false
                    })


                    if(data.data.map.loginBuddy) {
                        this.setState({
                            loginBuddy: data.data.map.loginBuddy.buddyName
                        })
                        this.formRef.current.setFieldsValue({
                            "buddy.wish": data.data.map.loginBuddy.wish,
                            "buddy.mood": data.data.map.loginBuddy.mood,
                            "buddy.nickName": data.data.map.loginBuddy.nickName,
                        })

                        if(data.data.map.loginInfo) {
                            this.formRef.current.setFieldsValue({
                                "buddy.email": data.data.map.loginInfo.map.email,
                                "buddy.birthday": data.data.map.loginInfo.map.birthday,
                                "buddy.question": data.data.map.loginInfo.map.question,
                                "buddy.answer": data.data.map.loginInfo.map.answer,
                                "buddy.phone": data.data.map.loginInfo.map.phone,
                            })
                        }
                    } else {
                        window.location.href="/site/buddy/login"
                    }


                }


            })

    }

    loadAuth(buddyName) {
        let formData = new FormData()
        formData.append('buddyName', buddyName)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/buddy/api/auth", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                console.log("auth data:", data)
                if(data.success) {


                    if(data.data.map.loginBuddy) {
                        this.setState({
                            auth: data,
                            loading: false
                        })
                    } else {
                        window.location.href="/site/buddy/login"
                    }


                }


            })

    }

    componentDidMount() {
        console.log("mount")
        this.loadSystemConfig({app: this.props.app})
        console.log(this.props.match.params.buddyName);
        var buddyName = this.props.match.params.buddyName

        this.loadHome(buddyName)

    }


    render() {
        return (
            <>
                <BuddyTitle content="修改资料" systemConfig={this.state.systemConfig}></BuddyTitle>

                <Layout>
                <Header>
                    <Space style={{color:'#ffffff'}}>
                    {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: this.toggleCollapsed,
                    })}
                </Space>
                </Header>
                    <Layout>

                <Sider collapsedWidth="0" trigger={null} collapsible collapsed={this.state.collapsed}>


                    <BuddyMenu buddyName={this.state.loginBuddy} />

                </Sider>
                <Content  style={{height:600,minWidth:200}}>
                    <Row justify="space-around">
                        <Col span={20}>
                    <Form ref={this.formRef}
                        name="buddy"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item wrapperCol={{ offset: 0, span: 8 }} label="">
                            <span className="ant-form-text"></span>
                        </Form.Item>


                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}
                                   label="昵称"
                                   name="buddy.nickName"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}
                                   label="email"
                                   name="buddy.email"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}
                                   label="手机号码"
                                   name="buddy.phone"

                        >
                            <Input />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}
                                   label="提示问题"
                                   name="buddy.question"

                        >
                            <Input />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}
                                   label="问题答案"
                                   name="buddy.answer"

                        >
                            <Input />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}
                                   label="生日"
                                   name="buddy.birthday"

                        >
                            <Input />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}
                                   label="心情"
                                   name="buddy.mood"

                        >
                            <Input />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}
                                   label="心愿|简介"
                                   name="buddy.wish"

                        >
                            <TextArea rows={4} />
                        </Form.Item>


                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                提交修改
                            </Button>
                        </Form.Item>
                    </Form>
                        </Col>
                    </Row>

                </Content>
                    </Layout>

            </Layout>
                </>
        );


    }

}

export default BuddyProfile;