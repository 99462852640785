import React from 'react';
import {message, Layout, Form, Input, Button, Checkbox, Space, Col, Row} from 'antd';
import cookie from 'react-cookies';
import BuddyTitle from "./BuddyTitle";
const { Header, Footer, Sider, Content } = Layout;




class Login extends React.Component {

    formRef = React.createRef();

    constructor(...args) {
        super(...args);
        this.state = {
            loading: false,
            systemConfig: {}
        };
    }

    onFinish = (values: any) => {


        let formData = new FormData()
        formData.append('buddyName', values.username)
        formData.append('password', values.password)
        formData.append('remember', values.remember)

        let host = process.env.REACT_APP_HOST

        fetch(host + "/site/buddy/api/loginApi", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {

                console.log("data:", data)
                if(data.success) {
                    message.info(data.msg)

                    cookie.save("JSESSIONID",data.session_id, {path:"/"})

                    if(values.remember) {
                        cookie.save("buddyName",values.username, { path: '/' })
                    }
                    this.setState({
                        login: true
                    })
                    window.location.href="/site/buddy/" + values.username + "/home"

                } else {
                    message.error(data.msg)
                }


            })


    };

    onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    loadSystemConfig(nextProps) {
        const {app} = nextProps
        let formData = new FormData()
        formData.append('app', app)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/view/systemConfig", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;
                this.setState({
                    systemConfig: data.data.map.systemConfig,
                    loading: false
                })

            })

    }

    componentDidMount() {
        console.log("mount")
        this.loadSystemConfig({app: this.props.app})


    }


    render() {
        return (
            <>
                    <BuddyTitle content="账号登录" systemConfig={this.state.systemConfig}></BuddyTitle>

                    <Layout>
                        <Header  style={{color:'#ffffff'}}>{this.state.systemConfig.systemNameCn} 账号登录</Header>
                        <Content  style={{height:400}}>
                            <Row justify="space-around">
                                <Col span={20}>
                            <Form ref={this.formRef}
                                name="basic"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}
                            >
                                <Form.Item wrapperCol={{ offset: 0, span: 8 }} label="">
                                    <span className="ant-form-text"></span>
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 0, span: 8 }}
                                    label="用户名"
                                    name="username"
                                    rules={[{ required: true, message: '请输入用户名!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 0, span: 8 }}
                                    label="密码"
                                    name="password"
                                    rules={[{ required: true, message: '请输入密码!' }]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                                    <Checkbox>记住我</Checkbox>
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit">
                                        登录
                                    </Button>
                                </Form.Item>
                            </Form>
                                </Col>
                            </Row>

                        </Content>
                        <Footer style={{height:60, backgroundColor: '#ffffff'}}>
                            <Row justify="space-around">
                                <Col span={6}>
                                    <Space direction='vertical'>
                                        <div>站点：<a href={this.state.systemConfig.domain}>{this.state.systemConfig.systemNameCn}</a></div>
                                        <div>Powered by {this.state.systemConfig.domain} ©{this.state.systemConfig.systemCopyRight}</div>
                                    </Space>
                                </Col>
                            </Row>

                        </Footer>
                    </Layout>
                </>
        );


    }

}

export default Login;