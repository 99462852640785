import React from 'react';
import {Typography, Modal, List, Avatar, Spin, message, Layout, Menu, Form, Input, Button, Checkbox, Space, Col, Row, Image} from 'antd';
import {Breadcrumb} from "antd/lib/breadcrumb";
import {
    LoadingOutlined,
    EditOutlined,
    HomeOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined,
} from '@ant-design/icons';

import BuddyMenu from "../BuddyMenu";
import BuddyTitle from "../BuddyTitle";
import NoteForm from "./NoteForm";
import NoteList from "./NoteList"

const { Header, Footer, Sider, Content } = Layout;

const { Paragraph, Text } = Typography;


class NoteDetail extends React.Component {

    constructor(...args) {
        super(...args);
        this.state = {
            modalVisible: false,
            loading: false,
            systemConfig: {},
            visiting: {},
            loginBuddy: "",
            collapsed: true,
            item:{},
            ellipsis: true,
        };




    }


    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };




    loadSystemConfig(nextProps) {
        const {app} = nextProps
        let formData = new FormData()
        formData.append('app', app)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/view/systemConfig", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;
                this.setState({
                    systemConfig: data.data.map.systemConfig,
                })

            })

    }


    loadAuth() {
        let formData = new FormData()

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/buddy/api/auth", {method: 'POST', body: formData, credentials: 'include',
            xhrFields: {
                withCredentials: true
            }})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                console.log("auth data:", data)
                if(data.success) {


                    if(data.data.map.loginBuddy) {
                        this.setState({
                            auth: data,
                            visiting: data,
                            loginBuddy: data.data.map.loginBuddy.buddyName,
                            noteDetail: null

                        })


                        console.log("load end")


                    } else {
                        window.location.href="/site/buddy/login"
                    }


                }


            })

    }

    loadDetail(buddyName, detailId, pageNo) {

        this.setState({
            loading: true
        })

        let formData = new FormData()
        formData.append('id', detailId)
        formData.append('buddyName', buddyName)

        let host = process.env.REACT_APP_HOST

        fetch(host + "/site/buddy/note/get", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {

                console.log("data.data:", data.data)

                var item = data.data.map.note

                this.setState({
                    item: item,
                })

                this.setState({
                    loading: false,
                })



            })

    }

    componentDidMount() {
        console.log("mount")
        this.loadSystemConfig({app: this.props.app})

        this.loadAuth()

        console.log(this.props.match.params.buddyName);
        var buddyName = this.props.match.params.buddyName

        console.log(this.props.match.params.id);
        var detailId = this.props.match.params.id

        this.loadDetail(buddyName, detailId, 1)

    }


    render() {

        return (
            <>
                <BuddyTitle content="好记" systemConfig={this.state.systemConfig}></BuddyTitle>

                <Layout>
                <Header>
                    <Space style={{color:'#ffffff'}}>
                    {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: this.toggleCollapsed,
                    })}
                </Space>
                </Header>
                    <Layout>

                <Sider collapsedWidth="0" trigger={null} collapsible collapsed={this.state.collapsed}>


                    <BuddyMenu buddyName={this.state.loginBuddy} />

                </Sider>
                <Content  style={{height:'auto'}}>

                    <Row justify="space-around">
                        <Col span={20}>



                            <Paragraph ellipsis={this.state.ellipsis ? { rows: 2, expandable: true, symbol: '>>' } : false}>
                                {this.state.item.content}
                            </Paragraph>

                        </Col>
                    </Row>

                </Content>
                    </Layout>

            </Layout>
                </>
        );


    }

}

export default NoteDetail;