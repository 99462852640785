import React from 'react';
import HomeHeader from './HomeHeader';
import HomeContainer from './HomeContainer';
import HomeFooter from './HomeFooter';
import './home.css';
import {Layout} from "antd";

const  {Content, Footer} = Layout;


class Home extends React.Component {

    constructor(props){
        super(props)
        this.state ={
            systemConfig: {}
        }
    }

    loadSystemConfig(nextProps) {
        const {app} = nextProps
        let formData = new FormData()
        formData.append('app', app)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        this.setState({loading: true})

        fetch(host + "/site/view/systemConfig", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;
                this.setState({
                    systemConfig: data.data.map.systemConfig,
                    loading: false
                })

            })

    }

    componentDidMount() {
        console.log("mount")
        this.loadSystemConfig({app: this.props.app})


    }

    render() {
        return (
            <>

                <HomeHeader systemConfig={this.state.systemConfig}></HomeHeader>
                <Layout>
                    <HomeContainer indexMenu={this.state.indexMenu} systemConfig={this.state.systemConfig}></HomeContainer>
                    <HomeFooter systemConfig={this.state.systemConfig}></HomeFooter>
                </Layout>
            </>
        )
    }

}

export default Home;
