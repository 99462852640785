import React from 'react';
import { Carousel, Tooltip, Row, Col, Layout, Menu, Button, Input, Space } from 'antd';

const contentStyle = {
    width:'100%',
    height: '100px',
    color: '#fff',
    lineHeight: '100px',
    textAlign: 'center',
    background: '#364d79',
};


class HomeSlider extends React.Component {

    constructor(...args) {
        super(...args);
        this.state = {
            initData: ''
        };
    }

    loadAdvertisement(nextProps) {
        const {type} = nextProps
        let formData = new FormData()
        formData.append('type', type)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        fetch(host + "/site/ads/list", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                this.setState({
                    indexBannerAdvertisementList: data.data.list
                })


            })

    }

    componentDidMount() {
        this.loadAdvertisement({type: 2})
    }

    render() {

        let items = []

        for(var advertisement in this.state.indexBannerAdvertisementList) {
            console.log("advertisement:", advertisement)
            var item = (
                <div style={{width:'100px', height:'300px'}}>
                    <a href={this.state.indexBannerAdvertisementList[advertisement].linkUrl}><img style={{width: '100%',height:'360px'}} src={this.state.indexBannerAdvertisementList[advertisement].attachment} alt={this.state.indexBannerAdvertisementList[advertisement].description}></img></a>
                    <h3 style={contentStyle}>{this.state.indexBannerAdvertisementList[advertisement].description}</h3>
                </div>
            );
            items.push(item)
        }

        let slider = (

                <Carousel autoplay>
                    {items}

                </Carousel>

        );
        return slider
    }

}

export default HomeSlider;
