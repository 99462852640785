import React from 'react';
import HomeNavigator from './HomeNavigator';
import HomeRecommendView from './HomeRecommendView';
import HomeView from './HomeView';
import HomeSlider from './HomeSlider';
import {Divider, Carousel, Tooltip, Row, Col, Layout, Menu, Button, Input, Space} from 'antd';

import {
    AppstoreOutlined,
    SearchOutlined,
    LoginOutlined,
    PieChartOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
} from '@ant-design/icons';

const {Header, Sider, Content, Footer } = Layout;

const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};


class HomeContainer extends React.Component {

    constructor(...args) {
        super(...args);
        this.state = {
            initData: '',
            collapsed: true,
        };
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    goLogin = () => {
        window.location.href="/site/buddy/login";
    }

    render() {

        let container = (
            <>
                <Layout style={{height:30, backgroundColor: '#ffffff'}}>

                </Layout>
                <Layout style={{height:60, backgroundColor: '#ffffff'}}>

                    <Row justify="space-around">
                        <Col span={8}>
                            <Space  align="center"  size={'middle'}>
                                <div></div>
                                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: this.toggleCollapsed,
                                })}
                                <div className="logo" ><a href={this.props.systemConfig.domain}>{this.props.systemConfig.systemNameCn}</a></div>
                                <div></div>

                            </Space>

                        </Col>
                        <Col align='right' span={6}>
                            <Space  align="center"  size={'middle'}>
                            <div><Button onClick={this.goLogin} icon={<LoginOutlined/>}>登录</Button></div>
                            <div></div>
                            </Space>

                        </Col>
                    </Row>
                </Layout>
                <Layout className="site-layout">
                    <Sider breakpoint="lg" collapsedWidth="0" trigger={null} collapsible collapsed={this.state.collapsed}>
                        <HomeNavigator category='0' systemConfig={this.props.systemConfig}></HomeNavigator>
                    </Sider>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 1,
                            minHeight: 280,
                        }}
                    >

                        <HomeSlider></HomeSlider>
                        <HomeRecommendView systemConfig={this.props.systemConfig}></HomeRecommendView>
                        <Divider />

                        <HomeView systemConfig={this.props.systemConfig}></HomeView>
                    </Content>
                </Layout>
            </>
        );
        return container
    }

}

export default HomeContainer;
