import React from 'react';

import { Tooltip, Row, Col, Layout, Menu, Button, Input, Space } from 'antd';

import {
    AppstoreOutlined,
    SearchOutlined,
    LoginOutlined,
    PieChartOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
} from '@ant-design/icons';

import HomeMenu from './HomeMenu';
import { Link } from 'react-router-dom'

const { Header, Sider, Content, Footer } = Layout;

const { Search } = Input;

const { SubMenu } = Menu;


class HomeNavigator extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {
            initData: '',
            show: false,
            menuItems: []
        };
    }





    onSearch  = (value) => {
        console.log(value)
    };

    loadNavigator(nextProps) {
        const {category} = nextProps
        let formData = new FormData()
        formData.append('category', category)

        let host = process.env.REACT_APP_HOST

        fetch(host + "/site/view/navigator", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;
                this.setState({
                    navigator: data.data.list
                })

                let menuItems = []

                let nav = this.state.navigator;
                console.log("nav:", nav)

                for(var i in nav) {
                    if(nav[i].subList) {

                        var sub = []

                        for(var s in nav[i].subList) {
                            var link = "/site/view/" + nav[i].subList[s].id
                            sub.push(<Menu.Item key={nav[i].subList[s].id}><Link to={link}>{nav[i].subList[s].title}</Link></Menu.Item>)

                        }
                        var link = "/site/view/" + nav[i].id
                        menuItems.push(<SubMenu key={nav[i].id} icon={<ContainerOutlined />} title={nav[i].title}><Link to={link}>{nav[i].title}</Link></SubMenu>);
                    } else {
                        var link = "/site/view/" + nav[i].id
                        menuItems.push(<Menu.Item key={nav[i].id} icon={<ContainerOutlined />}><Link to={link}>{nav[i].title}</Link></Menu.Item>);
                    }

                }

                this.setState({
                    menuItems: menuItems
                })


            })

    }

    componentDidMount() {
        this.loadNavigator({category: this.props.category})
    }

    render() {
        return (
                <HomeMenu menuItem={this.state.menuItems}></HomeMenu>

        );
    }
}
export default HomeNavigator;
