import React from 'react';
import {Divider, Card, Button, Space, Row, Col, Layout} from "antd";

const  {Content, Footer} = Layout;


class ViewFooter extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            initData: ''
        };

    }

    componentDidMount() {
    }

    render() {


        return (

            <Layout>
                <Divider />

                <Footer>
                    <Space direction='vertical'>
                        <div>站点：<a href={this.props.systemConfig.domain}>{this.props.systemConfig.systemNameCn}</a></div>
                        <div>Powered by {this.props.systemConfig.domain} ©{this.props.systemConfig.systemCopyRight}</div>
                        <div>ICP备案号：<a href="http://www.miibeian.gov.cn/">{this.props.systemConfig.systemIcpNo}</a></div>
                    </Space>
                </Footer>
                <Divider />

            </Layout>
        )
    }

}

export default ViewFooter;
