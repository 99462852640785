import React from 'react';
import { Spin, Pagination, Image, Typography, Divider, Breadcrumb, Card, Carousel, Tooltip, Row, Col, Layout, Menu, Button, Input, Space } from 'antd';

import { HomeOutlined, ContainerOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const { Meta } = Card;

class ViewView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initData: '',
            viewPlate:{},
            loading: false
        };

    }


    loadSubView(viewId, pageNo) {
        console.log("viewId: ", viewId)
        console.log("pageNo:", pageNo)
        let formData = new FormData()
        formData.append('id', viewId)
        formData.append('pageNo', pageNo)

        let host = process.env.REACT_APP_HOST

        this.setState({
            loading: true
        })

        fetch(host + "/site/view/subPage", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {

                console.log("data.data:", data.data)
                this.setState({
                    subView: data.data.map.pageModel.recordList,
                    page: data.data.map.pageModel,
                    loading: false
                })


            })

    }

    onChange = (page) => {
        console.log(page);
        this.setState({
            current: page,
        });

        var viewId = this.state.viewPlate.id

        this.loadSubView(viewId, page)
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            subView: this.props.subView,
            viewPlate: this.props.viewPlate,
            page: this.props.page,
            loading: false
        })

    }


    render() {

        let items = []

        console.log("this.state.subView:", this.state.subView)
        console.log("this.state.viewPlate.id:", this.state.viewPlate.id)

        for(var subView in this.state.subView) {
            console.log("subView:", subView)
            var link = "/site/view/" + this.state.subView[subView].id
            var item = (
                <Link to={link}>
                    <Card
                        hoverable
                        style={{ width: 230 }}
                        cover={<img height="300" alt={this.state.subView[subView].title} src={this.state.subView[subView].attachment} />}
                    >
                        <Meta title={this.state.subView[subView].title} description={this.state.subView[subView].description} />
                    </Card>
                </Link>
            );
            items.push(item)
        }

        var html = {__html:this.state.viewPlate.content};
        var content = <div dangerouslySetInnerHTML={html}></div>;
                    var attachment = <></>
        if(this.state.viewPlate.attachment) {
            attachment = <Image src={this.state.viewPlate.attachment} />
        }

        let views = (

            <div style={{backgroundColor:'#ffffff', padding: 10, margin: 0}}>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <Link to="/"><HomeOutlined /></Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>{this.state.viewPlate.title}</Breadcrumb.Item>
                </Breadcrumb>
                <Divider />

                <Row>
                    <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                    </Col>
                    <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                        <Space align='center'>{attachment}</Space>
                    </Col>
                    <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {items.length>0?<Pagination showSizeChanger={false} onChange={this.onChange} defaultCurrent={this.state.page.pageNo} pageSize={this.state.page.pageSize} total={this.state.page.totalRecord} />:<p></p>}
                    </Col>

                </Row>
                <Spin indicator={antIcon} spinning={this.state.loading}>
                <Space  align='start' wrap>

                    {items.length>0?items:content}
                </Space>
                </Spin>

            </div>
        );
        return views
    }

}

export default ViewView;
