import React from 'react';

import HomeTitle from './HomeTitle';
import HomeKeyWords from './HomeKeyWords';
import HomeDescription from './HomeDescription';
import HomeContentMeta from './HomeContentMeta';

class HomeHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initData: ''
        };
    }

    render() {

        let header = (
                <>
                    <HomeTitle systemConfig={this.props.systemConfig}></HomeTitle>
                    <HomeKeyWords systemConfig={this.props.systemConfig}></HomeKeyWords>
                    <HomeDescription systemConfig={this.props.systemConfig}></HomeDescription>
                    <HomeContentMeta systemConfig={this.props.systemConfig}></HomeContentMeta>
                </>

        );
        return header
    }

}

export default HomeHeader;
