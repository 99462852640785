import React from 'react';
import {Divider, Card, Button, Space, Row, Col, Layout} from "antd";

const  {Content, Footer} = Layout;


class HomeFooter extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            initData: ''
        };

    }

    loadPathList(nextProps) {
        const {site} = nextProps
        let formData = new FormData()
        formData.append('site', site)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        fetch(host + "/site/view/pathList", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                console.log("data.data:", data.data)
                this.setState({
                    pathList: data.data.map.list
                })


            })

    }

    loadUseUrlList(nextProps) {
        const {site} = nextProps
        let formData = new FormData()
        formData.append('site', site)

        let host = process.env.REACT_APP_HOST
        console.log("host:", host)
        console.log("env:", process.env)

        fetch(host + "/site/view/useUrlList", {method: 'POST', body: formData})
            .then(response => response.json())
            .then(data => {
                if(this.unmount) return;

                console.log("data.data:", data.data)

                var mapList = data.data.map.list
                var useUrlList = []
                for(var u in mapList) {
                    var useUrl = {}
                    useUrl['title'] = mapList[u].title
                    useUrl['url'] = '/site/view/'+mapList[u].id

                    useUrlList.push(useUrl)
                }
                this.setState({
                    useUrlList: useUrlList
                })


            })

    }

    componentDidMount() {
        this.loadPathList({site: this.props.systemConfig.domainConfig})
        this.loadUseUrlList({site: this.props.systemConfig.domainConfig})
    }

    render() {

        let pathList = []
        console.log("this.state.pathList:", this.state.pathList)

        for(var path in this.state.pathList) {
            console.log("path:", path)
            let subList = []
            for(var sub in this.state.pathList[path].subList) {
                subList.push(<p>{this.state.pathList[path].subList[sub].title}</p>)
            }
            var link = '/site/view/' + this.state.pathList[path].id
            var item = (
                <Col style={{padding:5}} xs={24} sm={24} md={24} lg={4} xl={4}>
                <a href={link}>
                    <Card style={{height:300}} title={this.state.pathList[path].title} bordered={false}>

                        {subList}
                    </Card>
                </a>
                </Col>
            );
            pathList.push(item)
        }

        let useUrlList = []
        console.log("this.state.useUrlList:", this.state.useUrlList)

        for(var useUrl in this.state.useUrlList) {
            console.log("useUrl:", useUrl)
            var item = (
                <a href={this.state.useUrlList[useUrl].url}>{this.state.useUrlList[useUrl].title}</a>

            );
            useUrlList.push(item)
        }
        return (

            <Layout>
                <Divider />
                <Layout>
                    <div className="site-card-wrapper">
                        <Row>
                            {pathList}
                        </Row>
                    </div>
                </Layout>
                <Divider />
                <Footer>
                    <div className="site-card-wrapper">
                        <Row>
                            <Col span={24}>
                                <Space wrap>
                                    友情链接
                                </Space>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Space wrap>
                                    {useUrlList}
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Footer>
                <Divider />
                <Footer>
                    <Space direction='vertical'>
                        <div>站点：<a href={this.props.systemConfig.domain}>{this.props.systemConfig.systemNameCn}</a></div>
                        <div>Powered by {this.props.systemConfig.domain} ©{this.props.systemConfig.systemCopyRight}</div>
                        <div>ICP备案号：<a href="http://www.miibeian.gov.cn/">{this.props.systemConfig.systemIcpNo}</a></div>
                    </Space>
                </Footer>
                <Divider />

            </Layout>
        )
    }

}

export default HomeFooter;
