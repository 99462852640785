import React from 'react';
import {message, Layout, Menu, Form, Input, Button, Checkbox, Space, Col, Row, Image} from 'antd';
import {Breadcrumb} from "antd/lib/breadcrumb";
import {
    EditOutlined,
    HomeOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined,
} from '@ant-design/icons';




class BuddyMenu extends React.Component {

    formRef = React.createRef();

    constructor(...args) {
        super(...args);
        this.state = {
            buddyName: "",
            selectedKeys: ["1"]
        };
    }


    handleClick = e => {

        if(e.key === "1") {

            if (this.props.buddyName) {
                window.location.href = "/site/buddy/" + this.props.buddyName + "/home"
            } else {
                window.location.href = "/site/buddy/login"
            }
        }


        if(e.key === "2") {

            if (this.props.buddyName) {
                window.location.href = "/site/buddy/" + this.props.buddyName + "/note"
            } else {
                window.location.href = "/site/buddy/login"
            }
        }

        if(e.key === "11") {

            if (this.props.buddyName) {
                window.location.href = "/site/buddy/" + this.props.buddyName + "/profile"
            } else {
                window.location.href = "/site/buddy/login"
            }
        }



        if(e.key === "12") {

            if (this.props.buddyName) {
                let formData = new FormData()
                formData.append('buddyName', this.props.buddyName)

                let host = process.env.REACT_APP_HOST

                fetch(host + "/site/buddy/api/logoutApi", {method: 'POST', body: formData})
                    .then(response => response.json())
                    .then(data => {

                        console.log("data:", data)
                        if (data.success) {
                            message.info(data.msg)

                            window.location.href = "/site/buddy/login"

                        }


                    })
            } else {
                window.location.href = "/site/buddy/login"
            }
        }

    };

    componentDidMount() {
        console.log("mount")

    }


    render() {
        return (


                    <Menu onClick={this.handleClick} theme="dark" mode="inline" defaultSelectedKeys={this.state.selectedKeys}>
                        <Menu.Item key="1" icon={<HomeOutlined />}>
                            主菜单
                        </Menu.Item>
                        <Menu.Item key="2" icon={<EditOutlined />}>
                            好记
                        </Menu.Item>
                        <Menu.Item key="11" icon={<UserOutlined />}>
                            修改资料
                        </Menu.Item>
                        <Menu.Item key="12" icon={<LogoutOutlined />}>
                            退出登录
                        </Menu.Item>

                    </Menu>


        );


    }

}

export default BuddyMenu;